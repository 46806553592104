const $ = jQuery

export default () => {
  $(function() {
    if (!$('.side-bar').length) return
    var $sidebar = $('.side-bar'), $social_links = $sidebar.find('.social_links'), side_bar_bottom = calc_sidebar_bottom()
    $(window).on('resize load', function() { side_bar_bottom = calc_sidebar_bottom(), anim() }).on('scroll', anim)
    function calc_sidebar_bottom() { return (($sidebar.offset().top + $sidebar.outerHeight()) + ((($(window).height() / 2) - ($social_links.height() / 2)))) }
    function anim() {
      var scroll_pos = ($(document).scrollTop() + $(window).height())
      if (scroll_pos >= side_bar_bottom) $social_links.addClass('change')
      else $social_links.removeClass('change')
    } anim()
  })
}
