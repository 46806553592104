const $ = jQuery

export default () => {
  $(function() {
    if (!$('#team-members .member').length) return
    var $popover = $('#teammember-popover')
    var $popover_content = $('#teammember-popover .content')

    $(window).on('click', function (e) {
      if ($popover.is(':visible') && !$(e.target).closest('#teammember-popover .content').length && !$(e.target).closest('#team-members').length) {
        $popover.fadeOut(300, function () {
          $popover_content.find('.member').hide()
        })
        $('body').css('overflow-y', 'auto')
      }
    })

    $popover_content.find('svg').on('click', function() {
      $(window).trigger('click');
    })

    $('#team-members .member').on('click', function() {
      $popover.find('.member[data-selector="' + $(this).data('selector') + '"]').css('display','flex')
      $popover.fadeIn()
      $('body').css('overflow-y', 'hidden')
    })
  })
}
