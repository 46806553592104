import Rellax from 'rellax'

const $ = jQuery

export default () => {
  $(function() {
    if ($('.rellax').length) {
      var rellax = new Rellax('.rellax', {
        center: true
      });
    }
  })
}
