import instantclick from './_instantclick';
import fonts from './_fonts';
import sidebar from './elements/_sidebar';
import slider from './elements/_slider';
import teammember_popover from './elements/_teammember_popover';
import scroll_to from './elements/_scroll_to';
import parallax from './elements/_parallax';

function init () {
  //instantclick();
  fonts();
  sidebar();
  slider();
  teammember_popover();
  scroll_to();
  parallax();
}

init();
