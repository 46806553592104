const $ = jQuery

export default () => {
  $(function() {
    if (!$('.slider').length) return
    $('.slider').each(function() {
      if ($(this).find('.slide').length <= 3) return
      $(this).removeClass('flex-row');
      $(this).flickity({
        wrapAround: true,
        pageDots: false,
        prevNextButtons: true,
        imagesLoaded: true,
        autoPlay: 5000
      }).find('.slide').css('opacity', 1)
    })
  })
}
